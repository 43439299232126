/* eslint-disable consistent-this */
export function injectProxy(adapterServicePrefix: string) {
  document.dispatchEvent(new CustomEvent(`${adapterServicePrefix}__inlineScriptsAllowed`))

  const addRuleDescriptor = Object.getOwnPropertyDescriptor(CSSStyleSheet.prototype, 'addRule')
  const insertRuleDescriptor = Object.getOwnPropertyDescriptor(
    CSSStyleSheet.prototype,
    'insertRule',
  )
  const deleteRuleDescriptor = Object.getOwnPropertyDescriptor(
    CSSStyleSheet.prototype,
    'deleteRule',
  )
  const removeRuleDescriptor = Object.getOwnPropertyDescriptor(
    CSSStyleSheet.prototype,
    'removeRule',
  )

  const documentStyleSheetsDescriptor = Object.getOwnPropertyDescriptor(
    Document.prototype,
    'styleSheets',
  )

  const addUndefinedResolver = (e: any) => {
    customElements.whenDefined(e?.detail?.tag).then(() => {
      document.dispatchEvent(
        new CustomEvent(`${adapterServicePrefix}__isDefined`, {detail: {tag: e?.detail?.tag}}),
      )
    })
  }

  const cleanUp = () => {
    Object.defineProperty(CSSStyleSheet.prototype, 'addRule', addRuleDescriptor!)
    Object.defineProperty(CSSStyleSheet.prototype, 'insertRule', insertRuleDescriptor!)
    Object.defineProperty(CSSStyleSheet.prototype, 'deleteRule', deleteRuleDescriptor!)
    Object.defineProperty(CSSStyleSheet.prototype, 'removeRule', removeRuleDescriptor!)
    document.removeEventListener(`${adapterServicePrefix}__cleanUp`, cleanUp)
    document.removeEventListener(
      `${adapterServicePrefix}__addUndefinedResolver`,
      addUndefinedResolver,
    )
    Object.defineProperty(Document.prototype, 'styleSheets', documentStyleSheetsDescriptor!)
  }

  document.addEventListener(`${adapterServicePrefix}__cleanUp`, cleanUp)
  document.addEventListener(`${adapterServicePrefix}__addUndefinedResolver`, addUndefinedResolver)

  const updateSheetEvent = new Event(`${adapterServicePrefix}__updateSheet`)

  function proxyAddRule(
    this: StyleSheet,
    selector?: string,
    style?: string,
    index?: number,
  ): number {
    addRuleDescriptor!.value.call(this, selector, style, index)
    const ownerNode = this.ownerNode as Element
    if (ownerNode && !ownerNode.classList.contains(adapterServicePrefix)) {
      ownerNode.dispatchEvent(updateSheetEvent)
    }
    return -1
  }

  function proxyInsertRule(this: CSSStyleSheet, rule: string, index?: number): number {
    const ownerNode = this.ownerNode as Element
    const returnValue = insertRuleDescriptor!.value.call(this, rule, index)
    if (ownerNode && !ownerNode.classList.contains(adapterServicePrefix)) {
      ownerNode.dispatchEvent(updateSheetEvent)
    }
    return returnValue
  }

  function proxyDeleteRule(this: CSSStyleSheet, index: number): void {
    const ownerNode = this.ownerNode as Element
    deleteRuleDescriptor!.value.call(this, index)
    if (ownerNode && !ownerNode.classList.contains(adapterServicePrefix)) {
      ownerNode.dispatchEvent(updateSheetEvent)
    }
  }

  function proxyRemoveRule(this: CSSStyleSheet, index?: number): void {
    const ownerNode = this.ownerNode as Element
    removeRuleDescriptor!.value.call(this, index)
    if (ownerNode && !ownerNode.classList.contains(adapterServicePrefix)) {
      ownerNode.dispatchEvent(updateSheetEvent)
    }
  }

  function proxyDocumentStyleSheets(this: StyleSheetList) {
    const getCurrentValue = () => {
      const docSheets: StyleSheetList = documentStyleSheetsDescriptor!.get!.call(this)

      const filteredSheets = [...docSheets].filter(
        styleSheet => !(styleSheet.ownerNode as Element).classList.contains(adapterServicePrefix),
      )

      ;(filteredSheets as unknown as StyleSheetList).item = (item: number) => filteredSheets[item]

      return Object.setPrototypeOf(filteredSheets, StyleSheetList.prototype)
    }

    let elements = getCurrentValue()

    const styleSheetListBehavior: ProxyHandler<StyleSheetList> = {
      get(_: StyleSheetList, property: string) {
        return getCurrentValue()[property]
      },
    }
    elements = new Proxy(elements, styleSheetListBehavior)
    return elements
  }

  Object.defineProperty(
    CSSStyleSheet.prototype,
    'addRule',
    Object.assign({}, addRuleDescriptor, {value: proxyAddRule}),
  )
  Object.defineProperty(
    CSSStyleSheet.prototype,
    'insertRule',
    Object.assign({}, insertRuleDescriptor, {value: proxyInsertRule}),
  )
  Object.defineProperty(
    CSSStyleSheet.prototype,
    'deleteRule',
    Object.assign({}, deleteRuleDescriptor, {value: proxyDeleteRule}),
  )
  Object.defineProperty(
    CSSStyleSheet.prototype,
    'removeRule',
    Object.assign({}, removeRuleDescriptor, {value: proxyRemoveRule}),
  )
  Object.defineProperty(
    Document.prototype,
    'styleSheets',
    Object.assign({}, documentStyleSheetsDescriptor, {get: proxyDocumentStyleSheets}),
  )
}
