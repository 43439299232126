import type Theme from '@jetbrains/ring-ui/components/global/theme'

enum MESSAGE_TYPES {
  CURRENT_THEME_REQUEST = 'CURRENT_THEME_REQUEST',
  CURRENT_THEME = 'CURRENT_THEME',
}
const kind = 'dark-theme-adapter-sync'

type MessageType =
  | {
      kind: typeof kind
      type: MESSAGE_TYPES.CURRENT_THEME
      payload: Theme
    }
  | {
      kind: typeof kind
      type: MESSAGE_TYPES.CURRENT_THEME_REQUEST
    }

const sendChangeThemeRequest = (theme: Theme, destination?: Window) => {
  if (!destination) {
    const destinations = document.querySelectorAll<HTMLIFrameElement>(
      'iframe[data-tc-iframe-darktheme-adapter]',
    )
    destinations.forEach(dst => {
      const iframeWindow = dst.contentWindow
      iframeWindow?.postMessage({
        kind,
        type: MESSAGE_TYPES.CURRENT_THEME,
        payload: theme,
      })
    })
  }
}

const notifyParentOnReadiness = (destination: Window = window.parent) => {
  destination.postMessage({
    kind,
    type: MESSAGE_TYPES.CURRENT_THEME_REQUEST,
  })
}

const onThemeChangeRequests = (theme: Theme) => {
  if (!window.ReactUI) {
    return
  }

  window.ReactUI.setGlobalTheme(theme)
  sendChangeThemeRequest(theme)
}

const onScriptsReady = () => {
  const currentTheme = window.ReactUI.store.getState().theme
  sendChangeThemeRequest(currentTheme)
}

const startListening = () => {
  const handler = (event: MessageEvent<MessageType>) => {
    const data = event.data
    if (data?.kind !== kind) {
      return
    }

    switch (data.type) {
      case MESSAGE_TYPES.CURRENT_THEME:
        onThemeChangeRequests(data.payload)
        break
      case MESSAGE_TYPES.CURRENT_THEME_REQUEST:
        onScriptsReady()
        break
      default:
        break
    }
  }

  window.addEventListener('message', handler)

  return () => window.removeEventListener('message', handler)
}

startListening()

export {sendChangeThemeRequest, notifyParentOnReadiness}
