/* eslint-disable @typescript-eslint/no-magic-numbers */
import clamp from 'lodash/clamp'

import type {Theme} from '../definitions'
import {FilterMode} from '../definitions'

import {parseColorWithCache} from '../utils/color'
import type {parsedGradient} from '../utils/parsing'
import {isPropertyRingOrTC, parseGradient} from '../utils/parsing'
import {getMatches} from '../utils/text'
import {getAbsoluteURL} from '../utils/url'

import {cssURLRegex, getCSSBaseBath, getCSSURLValue} from './css-rules'
import type {ImageDetails} from './image'
import {cleanImageProcessingCache, getFilteredImageDataURL, getImageDetails} from './image'
import {
  clearColorModificationCache,
  modifyBackgroundColor,
  modifyBorderColor,
  modifyForegroundColor,
  modifyGradientColor,
  modifyShadowColor,
} from './modify-colors'
import type {CSSVariableModifier, VariablesStore} from './variables'

export type CSSValueModifier = (theme: Theme) => string | Promise<string | null>

interface CSSValueModifierResult {
  result: string
  matchesLength: number
  unparseableMatchesLength: number
}

type CSSValueModifierWithInfo = (theme: Theme) => CSSValueModifierResult

export interface ModifiableCSSDeclaration {
  property: string
  value: string | CSSValueModifier | CSSVariableModifier
  important: boolean
  sourceValue: string
}

export interface ModifiableCSSRule {
  selector: string
  parentRule: CSSRule
  declarations: ModifiableCSSDeclaration[]
}
function getPriority(ruleStyle: CSSStyleDeclaration, property: string) {
  return Boolean(ruleStyle?.getPropertyPriority(property))
}

export function getModifiableCSSDeclaration(
  property: string,
  value: string,
  rule: CSSStyleRule,
  variablesStore: VariablesStore,
  isCancelled: (() => boolean) | null = null,
): ModifiableCSSDeclaration | null {
  if (isPropertyRingOrTC(property)) {
    return null
  }

  if (property.startsWith('--')) {
    const modifier = getVariableModifier(variablesStore, property, value, rule, isCancelled!)
    if (modifier) {
      return {
        property,
        value: modifier,
        important: getPriority(rule.style, property),
        sourceValue: value,
      }
    }
  } else if (value.includes('var(')) {
    const modifier = getVariableDependantModifier(variablesStore, property, value)
    if (modifier) {
      return {
        property,
        value: modifier,
        important: getPriority(rule.style, property),
        sourceValue: value,
      }
    }
  } else if (property === 'color-scheme') {
    return null
  } else if (
    (property.includes('color') && property !== '-webkit-print-color-adjust') ||
    property === 'fill' ||
    property === 'stroke' ||
    property === 'stop-color'
  ) {
    const modifier = getColorModifier(property, value, rule)
    if (modifier) {
      return {
        property,
        value: modifier,
        important: getPriority(rule.style, property),
        sourceValue: value,
      }
    }
  } else if (property === 'background-image' || property === 'list-style-image') {
    const modifier = getBgImageModifier(value, rule, isCancelled!)
    if (modifier) {
      return {
        property,
        value: modifier,
        important: getPriority(rule.style, property),
        sourceValue: value,
      }
    }
  } else if (property.includes('shadow')) {
    const modifier = getShadowModifier(value)
    if (modifier) {
      return {
        property,
        value: modifier,
        important: getPriority(rule.style, property),
        sourceValue: value,
      }
    }
  }
  return null
}

function joinSelectors(...selectors: string[]) {
  return selectors.filter(Boolean).join(', ')
}

export function getModifiedUserAgentStyle(theme: Theme) {
  const lines: string[] = []
  const bgSelectors = joinSelectors(`input`, `textarea`, `select`, `button`, `dialog`)
  lines.push(`${bgSelectors} {`)
  lines.push(
    `    background-color: var(--ring-input-background-color, ${modifyBackgroundColor(
      {r: 255, g: 255, b: 255},
      theme,
    )});`,
  )
  lines.push('}')
  lines.push(`${joinSelectors(`body`, `input`, `textarea`, `select`, `button`)} {`)

  lines.push(
    `    border-color: var(--ring-borders-color, ${modifyBorderColor(
      {r: 76, g: 76, b: 76},
      theme,
    )});`,
  )
  lines.push(
    `    color: var(--ring-text-color, ${modifyForegroundColor({r: 0, g: 0, b: 0}, theme)});`,
  )
  lines.push('}')
  lines.push(`a {`)
  lines.push(
    `    color: var(--ring-link-color, ${modifyForegroundColor({r: 0, g: 64, b: 255}, theme)});`,
  )
  lines.push('}')
  lines.push(`table {`)
  lines.push(
    `    border-color: var(--ring-borders-color, ${modifyBorderColor(
      {r: 128, g: 128, b: 128},
      theme,
    )});`,
  )
  lines.push('}')
  lines.push(`::placeholder {`)
  lines.push(
    `    color: var(--ring-text-color, ${modifyForegroundColor({r: 169, g: 169, b: 169}, theme)});`,
  )
  lines.push('}')
  lines.push(`input:-webkit-autofill,`)
  lines.push(`textarea:-webkit-autofill,`)
  lines.push(`select:-webkit-autofill {`)
  lines.push(
    `    background-color: ${modifyBackgroundColor({r: 250, g: 255, b: 189}, theme)} !important;`,
  )
  lines.push(
    `    color: var(--ring-text-color, ${modifyForegroundColor(
      {r: 0, g: 0, b: 0},
      theme,
    )}) !important;`,
  )
  lines.push('}')
  lines.push(getModifiedSelectionStyle(theme))

  return lines.join('\n')
}

export function getSelectionColor(theme: Theme) {
  const selectionColors =
    theme.mode === FilterMode.dark
      ? theme.darkSchemeSelectionColors
      : theme.lightSchemeSelectionColors

  return {
    backgroundColorSelection: selectionColors.bg,
    foregroundColorSelection: selectionColors.fg,
  }
}

function getModifiedSelectionStyle(theme: Theme) {
  const lines: string[] = []
  const {backgroundColorSelection, foregroundColorSelection} = getSelectionColor(theme)

  lines.push(`::selection {`)
  lines.push(`    background-color: ${backgroundColorSelection} !important;`)
  lines.push(`    color: ${foregroundColorSelection} !important;`)
  lines.push('}')

  return lines.join('\n')
}

export function getModifiedFallbackStyle(filter: Theme) {
  const lines: string[] = []
  lines.push(`{`)
  lines.push(
    `    background-color: ${modifyBackgroundColor({r: 255, g: 255, b: 255}, filter)} !important;`,
  )
  lines.push(`    border-color: ${modifyBorderColor({r: 64, g: 64, b: 64}, filter)} !important;`)
  lines.push(`    color: ${modifyForegroundColor({r: 0, g: 0, b: 0}, filter)} !important;`)
  lines.push('}')
  return lines.join('\n')
}

const unparsableColors = new Set([
  'inherit',
  'transparent',
  'initial',
  'currentcolor',
  'none',
  'unset',
])

function getColorModifier(
  prop: string,
  value: string,
  rule: CSSStyleRule,
): string | CSSValueModifier | null {
  if (unparsableColors.has(value.toLowerCase())) {
    return value
  }
  const rgb = parseColorWithCache(value)
  if (!rgb) {
    return null
  }

  if (prop.includes('background')) {
    if (
      (rule.style.mask && rule.style.mask !== 'none') ||
      (rule.style.getPropertyValue('mask-image') &&
        rule.style.getPropertyValue('mask-image') !== 'none')
    ) {
      return filter => modifyForegroundColor(rgb, filter)
    }
    return filter => modifyBackgroundColor(rgb, filter)
  }
  if (prop.includes('border') || prop.includes('outline')) {
    return filter => modifyBorderColor(rgb, filter)
  }
  return filter => modifyForegroundColor(rgb, filter)
}

const imageDetailsCache = new Map<string, ImageDetails>()
const awaitingForImageLoading = new Map<
  string,
  Array<(imageDetails: ImageDetails | null) => void>
>()

interface bgImageMatches {
  type: 'url' | 'gradient'
  index: number
  match: string
  offset: number
  typeGradient?: string
  hasComma?: boolean
}

export function getBgImageModifier(
  value: string,
  rule: CSSStyleRule,
  isCancelled: () => boolean,
): string | CSSValueModifier | null {
  try {
    const gradients = parseGradient(value)
    const urls = getMatches(cssURLRegex, value)

    if (urls.length === 0 && gradients.length === 0) {
      return value
    }

    const getIndices = (matches: string[]) => {
      let index = 0
      return matches.map(match => {
        const valueIndex = value.indexOf(match, index)
        index = valueIndex + match.length
        return {match, index: valueIndex}
      })
    }

    const matches: bgImageMatches[] = (
      gradients.map(i => ({
        type: 'gradient',
        ...i,
      })) as bgImageMatches[]
    )
      .concat(getIndices(urls).map(i => ({type: 'url', offset: 0, ...i})))
      .sort((a, b) => (a.index > b.index ? 1 : -1))

    const getGradientModifier = (gradient: parsedGradient) => {
      const {typeGradient, match, hasComma} = gradient

      const partsRegex =
        /([^\(\),]+(\([^\(\)]*(\([^\(\)]*\)*[^\(\)]*)?\))?([^\(\), ]|( (?!calc)))*),?/g
      const colorStopRegex = /^(from|color-stop|to)\(([^\(\)]*?,\s*)?(.*?)\)$/

      const parts = getMatches(partsRegex, match, 1).map(item => {
        const part = item.trim()

        let rgb = parseColorWithCache(part)
        if (rgb) {
          return (filter: Theme) => modifyGradientColor(rgb!, filter)
        }

        const space = part.lastIndexOf(' ')
        rgb = parseColorWithCache(part.substring(0, space))
        if (rgb) {
          return (filter: Theme) =>
            `${modifyGradientColor(rgb!, filter)} ${part.substring(space + 1)}`
        }

        const colorStopMatch = part.match(colorStopRegex)
        if (colorStopMatch) {
          rgb = parseColorWithCache(colorStopMatch[3])
          if (rgb) {
            return (filter: Theme) =>
              `${colorStopMatch[1]}(${
                colorStopMatch[2] ? `${colorStopMatch[2]}, ` : ''
              }${modifyGradientColor(rgb!, filter)})`
          }
        }

        return () => part
      })

      return (filter: Theme) =>
        `${typeGradient}(${parts.map(modify => modify(filter)).join(', ')})${hasComma ? ', ' : ''}`
    }

    const getBgImageValue = (imageDetails: ImageDetails, filter: Theme) => {
      const {isDark, isLight, isTransparent, isLarge, isTooLarge, width} = imageDetails
      let result: string | null
      if (isTooLarge) {
        result = `url("${imageDetails.src}")`
      } else if (isDark && isTransparent && filter.mode === 1 && !isLarge && width > 2) {
        const inverted = getFilteredImageDataURL(imageDetails, {
          ...filter,
          sepia: clamp(filter.sepia + 10, 0, 100),
        })
        result = `url("${inverted}")`
      } else if (isLight && !isTransparent && filter.mode === 1) {
        if (isLarge) {
          result = 'none'
        } else {
          const dimmed = getFilteredImageDataURL(imageDetails, filter)
          result = `url("${dimmed}")`
        }
      } else if (filter.mode === 0 && isLight && !isLarge) {
        const filtered = getFilteredImageDataURL(imageDetails, {
          ...filter,
          brightness: clamp(filter.brightness - 10, 5, 200),
          sepia: clamp(filter.sepia + 10, 0, 100),
        })
        result = `url("${filtered}")`
      } else {
        result = null
      }
      return result
    }

    const getURLModifier = (urlValue: string) => {
      let url = getCSSURLValue(urlValue)
      const isURLEmpty = url.length === 0
      const {parentStyleSheet} = rule
      const baseURL = parentStyleSheet?.href
        ? getCSSBaseBath(parentStyleSheet.href)
        : parentStyleSheet!.ownerNode?.baseURI || location.origin
      url = getAbsoluteURL(baseURL, url)

      const absoluteValue = `url("${url}")`

      return async (filter: Theme): Promise<string | null> => {
        if (isURLEmpty) {
          return "url('')"
        }
        let imageDetails: ImageDetails | null
        if (imageDetailsCache.has(url)) {
          imageDetails = imageDetailsCache.get(url)!
        } else {
          try {
            if (awaitingForImageLoading.has(url)) {
              const awaiters = awaitingForImageLoading.get(url)!
              imageDetails = await new Promise<ImageDetails | null>(resolve =>
                awaiters.push(resolve),
              )
              if (!imageDetails) {
                return null
              }
            } else {
              awaitingForImageLoading.set(url, [])
              imageDetails = await getImageDetails(url)
              imageDetailsCache.set(url, imageDetails)
              awaitingForImageLoading.get(url)!.forEach(resolve => resolve(imageDetails))
              awaitingForImageLoading.delete(url)
            }
            if (isCancelled()) {
              return null
            }
          } catch (err) {
            if (awaitingForImageLoading.has(url)) {
              awaitingForImageLoading.get(url)!.forEach(resolve => resolve(null))
              awaitingForImageLoading.delete(url)
            }
            return absoluteValue
          }
        }
        return getBgImageValue(imageDetails, filter) || absoluteValue
      }
    }

    const modifiers: Array<CSSValueModifier | null> = []

    let matchIndex = 0
    let prevHasComma = false
    matches.forEach(({type, match, index, typeGradient, hasComma, offset}, i) => {
      const matchStart = index
      const prefixStart = matchIndex
      const matchEnd = matchStart + match.length + offset
      matchIndex = matchEnd

      if (prefixStart !== matchStart) {
        if (prevHasComma) {
          modifiers.push(() => {
            let betweenValue = value.substring(prefixStart, matchStart)
            if (betweenValue[0] === ',') {
              betweenValue = betweenValue.substring(1)
            }
            return betweenValue
          })
        } else {
          modifiers.push(() => value.substring(prefixStart, matchStart))
        }
      }
      prevHasComma = hasComma || false

      if (type === 'url') {
        modifiers.push(getURLModifier(match))
      } else if (type === 'gradient') {
        modifiers.push(
          getGradientModifier({
            match,
            index,
            typeGradient: typeGradient as string,
            hasComma: hasComma || false,
            offset,
          }),
        )
      }

      if (i === matches.length - 1) {
        modifiers.push(() => value.substring(matchEnd))
      }
    })

    return (filter: Theme) => {
      const results = modifiers.filter(Boolean).map(modify => modify!(filter))
      if (results.some(r => r instanceof Promise)) {
        return Promise.all(results).then(asyncResults => asyncResults.filter(Boolean).join(''))
      }
      const combinedResult = results.join('')
      if (combinedResult.endsWith(', initial')) {
        return combinedResult.slice(0, -9)
      }
      return combinedResult
    }
  } catch (err) {
    return null
  }
}

export function getShadowModifierWithInfo(value: string): CSSValueModifierWithInfo | null {
  try {
    let index = 0
    const colorMatches = getMatches(
      /(^|\s)(?!calc)([a-z]+\(.+?\)|#[0-9a-f]+|[a-z]+)(.*?(inset|outset)?($|,))/gi,
      value,
      2,
    )
    let notParsed = 0
    const modifiers = colorMatches.map((match, i) => {
      const prefixIndex = index
      const matchIndex = value.indexOf(match, index)
      const matchEnd = matchIndex + match.length
      index = matchEnd
      const rgb = parseColorWithCache(match)
      if (!rgb) {
        notParsed++
        return () => value.substring(prefixIndex, matchEnd)
      }
      return (filter: Theme) =>
        `${value.substring(prefixIndex, matchIndex)}${modifyShadowColor(rgb, filter)}${
          i === colorMatches.length - 1 ? value.substring(matchEnd) : ''
        }`
    })

    return (filter: Theme) => {
      const modified = modifiers.map(modify => modify(filter)).join('')
      return {
        matchesLength: colorMatches.length,
        unparseableMatchesLength: notParsed,
        result: modified,
      }
    }
  } catch (err) {
    return null
  }
}

function getShadowModifier(value: string): CSSValueModifier | null {
  const shadowModifier = getShadowModifierWithInfo(value)
  if (!shadowModifier) {
    return null
  }
  return (theme: Theme) => shadowModifier(theme).result
}

function getVariableModifier(
  variablesStore: VariablesStore,
  prop: string,
  value: string,
  rule: CSSStyleRule,
  isCancelled: () => boolean,
): CSSVariableModifier {
  return variablesStore.getModifierForVariable({
    varName: prop,
    sourceValue: value,
    rule,
    isCancelled,
  })
}

function getVariableDependantModifier(variablesStore: VariablesStore, prop: string, value: string) {
  return variablesStore.getModifierForVarDependant(prop, value)
}

export function cleanModificationCache() {
  clearColorModificationCache()
  imageDetailsCache.clear()
  cleanImageProcessingCache()
  awaitingForImageLoading.clear()
}
