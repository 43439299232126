import type {Theme} from './definitions'

export const darkThemeAdapterServicePrefix = 'tc-dark-theme-adapter'
export const isGlobalDarkModeAllowed =
  window.internalProps['teamcity.ui.darkTheme.allowed'] === true

const DEFAULT_COLORS = {
  darkScheme: {
    background: '#23272b',
    text: '#bbb',
    selection: {
      bg: 'rgba(153, 213, 255, 0.5)',
      fg: '#bbb',
    },
  },
  lightScheme: {
    background: '#dcdad7',
    text: '#181a1b',
    selection: {
      bg: 'rgba(153, 213, 255, 0.5)',
      fg: '#fff',
    },
  },
}

export const DEFAULT_THEME: Theme = {
  mode: 1,
  brightness: 100,
  contrast: 100,
  grayscale: 0,
  sepia: 0,
  stylesheet: '',
  darkSchemeBackgroundColor: DEFAULT_COLORS.darkScheme.background,
  darkSchemeTextColor: DEFAULT_COLORS.darkScheme.text,
  darkSchemeSelectionColors: DEFAULT_COLORS.darkScheme.selection,
  lightSchemeBackgroundColor: DEFAULT_COLORS.lightScheme.background,
  lightSchemeTextColor: DEFAULT_COLORS.lightScheme.text,
  lightSchemeSelectionColors: DEFAULT_COLORS.lightScheme.selection,
}
