import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {fetchable} from '../../../reducers/fetchable'
import type {Fetchable, ProjectInternalId} from '../../../types'
import {emptyArray, emptyNullFetchable} from '../../../utils/empty'
import type {KeyValue} from '../../../utils/object'

import {
  disableProjectPolicyAction,
  editPolicy,
  fetchProjectPoliciesAction,
  getDiskUsage,
  resetPolicy,
} from './CleanupProjectPage.actions'
import {emptyDiskUsage} from './CleanupProjectPage.types'
import type {CleanupPoliciesRequestActions, PoliciesResponse} from './CleanupProjectPage.types'

const policiesGetReducer = fetchable(
  fetchProjectPoliciesAction,
  null,
  () => fetchProjectPoliciesAction.typePrefix,
)
const policiesUpdateReducer = fetchable(editPolicy, null, () => editPolicy.typePrefix)
const policiesSetDisabledReducer = fetchable(
  disableProjectPolicyAction,
  null,
  () => disableProjectPolicyAction.typePrefix,
)
const policiesResetReducer = fetchable(resetPolicy, null, () => resetPolicy.typePrefix)

const entities = createReducer<KeyValue<ProjectInternalId, PoliciesResponse>>({}, builder => {
  builder.addCase(fetchProjectPoliciesAction.fulfilled, (state, action) => {
    state[action.meta.arg.projectInternalId] = action.payload || {
      policiesPerProject: [],
    }
  })
})

const errors = createReducer(emptyArray, builder => {
  builder.addCase(fetchProjectPoliciesAction.pending, () => emptyArray)
  builder.addCase(fetchProjectPoliciesAction.fulfilled, () => emptyArray)
  builder.addCase(editPolicy.pending, () => emptyArray)
  builder.addCase(editPolicy.fulfilled, () => emptyArray)
  builder.addCase(editPolicy.rejected, (_, action) => [action.error])
  builder.addMatcher(
    isAnyOf(fetchProjectPoliciesAction.rejected, resetPolicy.rejected),
    (state, action) => (action.payload ? [action.payload] : emptyArray),
  )
})

const requestStatus = createReducer<Fetchable<CleanupPoliciesRequestActions | null>>(
  emptyNullFetchable,
  builder => {
    builder.addMatcher(
      action => action.type.startsWith(fetchProjectPoliciesAction.typePrefix),
      policiesGetReducer,
    )
    builder.addMatcher(
      action => action.type.startsWith(editPolicy.typePrefix),
      policiesUpdateReducer,
    )
    builder.addMatcher(
      action => action.type.startsWith(resetPolicy.typePrefix),
      policiesResetReducer,
    )
    builder.addMatcher(
      action => action.type.startsWith(disableProjectPolicyAction.typePrefix),
      policiesSetDisabledReducer,
    )
  },
)

const diskUsage = createReducer(emptyDiskUsage, builder =>
  builder.addCase(getDiskUsage.fulfilled, (state, action) => {
    Object.assign(state, action.payload)
  }),
)

const cleanupPoliciesReducer = Redux.combineReducers({
  requestStatus,
  entities,
  errors,
  diskUsage,
})
/** Add exported reducer to the end of mainReducer in ~/reducers/index.js */

export default cleanupPoliciesReducer
