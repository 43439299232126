import {archivedProjectsOptions, getProjectsArg, getSingleProjectArg} from '../rest/projects'
import {getProject} from '../selectors'
import {restApi} from '../services/rest'
import type {ProjectId, RequestOptionsParams} from '../types'
import {ROOT_PROJECT_ID} from '../types'

import type {Refetchable} from '../utils/refetchable'
import {combineRefetchables} from '../utils/refetchable'

import type {AppThunk} from './types'

export const fetchSingleProjectData = (projectId: ProjectId, options?: RequestOptionsParams) =>
  restApi.endpoints.getProjectNormalized.initiate(getSingleProjectArg(projectId, options))
export const fetchProjectWithAllParentsData =
  (projectId: ProjectId): AppThunk<Refetchable> =>
  (dispatch, getState) => {
    let parentProjectId: ProjectId | null | undefined = projectId
    const refetchables: Refetchable[] = []

    const cycle = async () => {
      while (
        parentProjectId &&
        (parentProjectId !== ROOT_PROJECT_ID || parentProjectId === projectId)
      ) {
        const state = getState()
        let parent = getProject(state, parentProjectId)

        if (parent == null) {
          const result = dispatch(fetchSingleProjectData(parentProjectId))
          refetchables.push(result)
          await result
          parent = getProject(getState(), parentProjectId)
        }

        parentProjectId = parent?.parentProjectId
      }
    }
    cycle()

    return combineRefetchables(refetchables)
  }
export const fetchProjectsWithArchivedData = () =>
  restApi.endpoints.getAllProjectsNormalized.initiate(
    getProjectsArg(ROOT_PROJECT_ID, {
      withBuildTypes: false,
      withLinks: true,
      archived: 'any',
    }),
  )

export const fetchArchivedProjects = () =>
  restApi.endpoints.getAllProjectsNormalized.initiate(
    getProjectsArg(ROOT_PROJECT_ID, archivedProjectsOptions),
  )
