import {DEFAULT_THEME} from './defaults'
import type {Theme} from './definitions'
import {createOrUpdateDarkTheme, removeDarkTheme} from './dynamic-theme'
import * as iframeManager from './dynamic-theme/iframes'

export const disable = () => removeDarkTheme()
export const enable = (themeOptions: Partial<Theme> | null = {}) =>
  createOrUpdateDarkTheme({...DEFAULT_THEME, ...themeOptions})

export {iframeManager}
