import * as React from 'react'

import {fetchCurrentUserData} from '../../../actions'
import {useAppDispatch} from '../../../hooks/react-redux'
import {getAgentsArg} from '../../../rest/agents'
import {agentsProjectsArg} from '../../../rest/projects'
import {restApi} from '../../../services/rest'
import type {AgentRequestOptions} from '../../../types'
import Fetcher from '../../common/Fetcher/Fetcher'
import type {FetcherProps} from '../../common/Fetcher/Fetcher'

export type AgentsFetcherProps = Omit<FetcherProps, 'fetchData'> & {
  additionalFields?: AgentRequestOptions
}

function LegacyAgentsFetcher({additionalFields, ...restProps}: AgentsFetcherProps) {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(fetchCurrentUserData())
  }, [dispatch])
  restApi.endpoints.getAllProjectsNormalized.useQuerySubscription(agentsProjectsArg)
  const [fetchData] = restApi.endpoints.getAllAgentsNormalized.useLazyQuerySubscription()
  const arg = React.useMemo(
    () => getAgentsArg(restProps.locator ?? undefined, additionalFields),
    [additionalFields, restProps.locator],
  )

  return <Fetcher {...restProps} fetchData={() => fetchData(arg)} />
}

export default LegacyAgentsFetcher
