import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {stringifyId} from '../../../types'
import type {ProjectInternalId} from '../../../types'
import {base_uri} from '../../../types/BS_types'

import {emptyDiskUsage} from './CleanupProjectPage.types'
import type {
  ACPGetDiskUsageProps,
  DiskUsage,
  PolicyHolder,
  UpdatePolicyPayload,
  DiskUsageNode,
} from './CleanupProjectPage.types'

const serverUrl = `${base_uri}/admin`
export const fetchPolicies: (
  projectInternalId: ProjectInternalId,
  options: {
    includeSubprojects: boolean
  },
) => Promise<any> = (projectInternalId, {includeSubprojects = false}) =>
  request(
    serverUrl,
    `cleanupPoliciesRest.html?baseProjectId=${stringifyId(
      projectInternalId,
    )}&includeSubprojectsPolicies=${includeSubprojects.toString()}`,
  ).then(processResponse)
export const resetPolicy = ({holderId, holderType}: PolicyHolder): Promise<any> =>
  request(serverUrl, 'cleanupPoliciesRest.html', {
    body: JSON.stringify({
      holderId: stringifyId(holderId),
      holder: holderType,
      type: 'resetSettings',
    }),
    method: 'POST',
  })
export const updatePolicy: (arg0: UpdatePolicyPayload) => Promise<any> = policy =>
  request(serverUrl, 'cleanupPoliciesRest.html', {
    body: JSON.stringify({...policy, type: 'updateSettings'}),
    method: 'POST',
  })
export const setPolicyDisable: (
  arg0: PolicyHolder,
  arg1: boolean | null | undefined,
) => Promise<any> = ({holderId, holderType}, cleanupPoliciesDisabled = false) =>
  request(serverUrl, 'cleanupPoliciesRest.html', {
    body: JSON.stringify({
      holderId: stringifyId(holderId),
      holder: holderType,
      cleanupPoliciesDisabled,
      type: 'updateDisableState',
    }),
    method: 'POST',
  })
export const getDiskUsage = async (projectId: ACPGetDiskUsageProps): Promise<DiskUsage> => {
  const res = await request(base_uri, `diskUsage.html`, {
    method: 'POST',
    body: `action=getData&projectId=${stringifyId(projectId)}`,
    headers: {
      Accept: 'text/xml',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  })
  const xml = new DOMParser().parseFromString(await res.text(), 'text/xml')
  const nodes = xml.querySelectorAll<DiskUsageNode>('project, buildType')
  const result = emptyDiskUsage
  nodes.forEach(node => {
    const type = node.nodeName
    const id = node.getAttribute('projectId') || node.getAttribute('buildTypeId')
    const size = node.getAttribute('size')
    if (id != null && size != null) {
      result[type][id] = parseInt(size, 10)
    }
  })
  return result
}
